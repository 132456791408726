import { createSelector } from "@reduxjs/toolkit";
import equal from "@superblocksteam/fast-deep-equal/es6";
import { TriggerStepType, WidgetTypes } from "@superblocksteam/shared";
import { omit } from "lodash";
import { isPlainObject } from "lodash";
import { getWidgets } from "legacy/selectors/entitiesSelector";
import { WidgetProps } from "legacy/widgets";
import {
  KeyValueProperty,
  KeyValueWidgetProps,
} from "legacy/widgets/KeyValueWidget/types";
import { ManualMenuItem } from "legacy/widgets/MenuWidget/types";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import { TableWidgetProps } from "legacy/widgets/TableWidget/TableWidgetConstants";
import {
  getColumnIdFromAi,
  getKvPropertyIdFromAi,
} from "store/slices/ai/columnUtils";
import { getMenuItemId } from "./menuUtils";
import {
  AddEventAction,
  ClarkComponentAction,
  ClarkAction,
  RemoveColumnAction,
  RemoveEventAction,
  SetColumnAction,
  SetTabAction,
  RemoveKvItemAction,
  SetKvPropertyAction,
} from "./types";
import type {
  TabsWidgetProps,
  TabContainerWidgetProps,
} from "legacy/widgets/TabsWidget/types";
import type { AppState } from "store/types";

export interface ColumnChange {
  columnId: string;
  action: SetColumnAction | RemoveColumnAction;
  column: ColumnProperties;
}

export interface PropertyChange {
  propertyId: string;
  action: ClarkComponentAction;
  property: KeyValueProperty;
}

export const selectAiState = (state: AppState) => state.ai;

export const selectIsEditingWithAi = createSelector(selectAiState, (state) => {
  return state.actionsRequestId !== undefined;
});

export const selectAiModalOpen = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId !== undefined && state.viewMode === "modal",
);

export const selectInitialPosition = createSelector(
  selectAiState,
  (state) => state.initialPosition,
);

export const selectAiEditedWidgetId = createSelector(
  selectAiState,
  (state) => state.selectedWidgetId,
);

export const selectAiApiStatuses = createSelector(
  selectAiState,
  (state) => state.apiStatuses,
);

export const selectAiDataTreeChangesById = createSelector(
  selectAiState,
  (state) => {
    if (!state.selectedWidgetId || !state.dataTreeChanges) {
      return undefined;
    }
    return state.dataTreeChanges as Record<
      string,
      Partial<Omit<WidgetProps, "children">>
    >;
  },
);

export const selectAiComponentDataTreeEdits = createSelector(
  selectAiState,
  (state) => {
    return state.dataTreeChanges;
  },
);

export const selectAiComponentPropertiesToChange = createSelector(
  selectAiState,
  (state) => {
    return state.propertiesToChange;
  },
);

export const selectAddedRemovedTableColumns = createSelector(
  [selectAiState, getWidgets, (_state: AppState, widgetId: string) => widgetId],
  (
    aiState,
    widgets,
    widgetId,
  ): { addedColumns: ColumnChange[]; removedColumns: ColumnChange[] } => {
    const emptyResult = {
      addedColumns: [],
      removedColumns: [],
    };
    if (!widgetId || !aiState.widgetsAtRequestStart) {
      return emptyResult;
    }

    const selectedWidget = widgets[widgetId];
    if (
      !selectedWidget ||
      selectedWidget.type !== WidgetTypes.TABLE_WIDGET ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const currentWidget = selectedWidget as TableWidgetProps;
    const widgetAtRequestStart = aiState.widgetsAtRequestStart?.[widgetId] as
      | TableWidgetProps
      | undefined;
    const existingColumns = Object.keys(
      widgetAtRequestStart?.primaryColumns || {},
    );

    const setActions = (aiState.actions || []).filter(
      (action: ClarkAction) =>
        action?.action === "set" &&
        "table_column" in action &&
        action?.table_column != null,
    );

    // Added columns with their actions
    const addedColumns = setActions
      .filter(
        (action) =>
          !existingColumns.includes((action as SetColumnAction).table_column),
      )
      .map((action) => {
        const primaryColumns = currentWidget?.primaryColumns as
          | Record<string, ColumnProperties>
          | undefined;
        const column =
          primaryColumns?.[(action as SetColumnAction).table_column];
        if (!column) return undefined;
        return {
          columnId: (action as SetColumnAction).table_column,
          action,
          column,
        };
      })
      .filter((item): item is ColumnChange => item !== undefined);

    const removeActions = (aiState.actions || []).filter(
      (action: ClarkAction) =>
        action?.action === "remove" &&
        "table_column" in action &&
        action.table_column != null,
    );

    // Removed columns with their actions
    const removedColumns = widgetAtRequestStart
      ? removeActions
          .map((action) => {
            const columnId = getColumnIdFromAi(
              (action as RemoveColumnAction).table_column,
              widgetAtRequestStart.primaryColumns,
            );

            if (!columnId || !widgetAtRequestStart.primaryColumns?.[columnId]) {
              return undefined;
            }
            // Skip non-derived columns as they just get hidden and not deleted
            if (!widgetAtRequestStart.primaryColumns[columnId].isDerived) {
              return undefined;
            }
            const column = widgetAtRequestStart.primaryColumns[columnId];
            return {
              columnId,
              action,
              column,
            };
          })
          .filter((item): item is ColumnChange => item !== undefined)
      : [];

    return {
      addedColumns,
      removedColumns,
    };
  },
);

export const selectAddedRemovedKeyValueProperties = createSelector(
  [selectAiState, (_state: AppState, widgetId: string) => widgetId],
  (
    aiState,
    widgetId,
  ): {
    addedProperties: PropertyChange[];
    removedProperties: PropertyChange[];
  } => {
    const emptyResult = {
      addedProperties: [],
      removedProperties: [],
    };
    if (!widgetId) {
      return emptyResult;
    }

    const _previousWidget = aiState.widgetsAtRequestStart?.[widgetId];
    if (
      !_previousWidget ||
      _previousWidget.type !== WidgetTypes.KEY_VALUE_WIDGET ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const previousWidget = _previousWidget as KeyValueWidgetProps;
    const existingProperties = Object.keys(previousWidget.properties);

    const setActions = (aiState.actions || []).filter(
      (action: ClarkAction) =>
        action?.action === "set" &&
        "key_value_property" in action &&
        action?.key_value_property != null,
    );

    // Added properties with their actions
    const addedProperties = setActions
      .filter(
        (action) =>
          !existingProperties.includes(
            (action as SetKvPropertyAction).key_value_property,
          ),
      )
      .map((action) => {
        const properties = aiState.dataTreeChanges?.properties as
          | Record<string, KeyValueProperty>
          | undefined;
        const property =
          properties?.[(action as SetKvPropertyAction).key_value_property];
        if (!property) return undefined;
        return {
          propertyId: (action as SetKvPropertyAction).key_value_property,
          action,
          property,
        };
      })
      .filter((item): item is PropertyChange => item !== undefined);

    const removeActions = (aiState.actions || []).filter(
      (action: ClarkAction) =>
        action?.action === "remove" &&
        "key_value_property" in action &&
        action.key_value_property != null,
    );

    // Removed properties with their actions
    const removedProperties = removeActions
      .map((action) => {
        const propertyId = getKvPropertyIdFromAi(
          (action as unknown as RemoveKvItemAction).key_value_property,
          previousWidget.properties,
        );
        if (!propertyId || !previousWidget.properties?.[propertyId]) {
          return undefined;
        }
        // Skip non-derived properties as they just get hidden and not deleted
        if (!previousWidget.properties[propertyId].isDerived) {
          return undefined;
        }
        const property = previousWidget.properties[propertyId];
        return {
          propertyId,
          action,
          property: property as KeyValueProperty,
        };
      })
      .filter((item): item is PropertyChange => item !== undefined);

    return {
      addedProperties,
      removedProperties,
    };
  },
);

export const selectAddedRemovedEventTriggers = createSelector(
  [
    selectAiState,
    getWidgets,
    (_state: AppState, widgetId?: string) => widgetId,
    (_state: AppState, _widgetId?: string, propertyName?: string) =>
      propertyName,
  ],
  (
    aiState,
    widgets,
    widgetId,
    propertyName,
  ): { addedTriggers: any[]; removedTriggers: any[] } => {
    const emptyResult = {
      addedTriggers: [],
      removedTriggers: [],
    };
    if (!widgetId || !propertyName) {
      return emptyResult;
    }

    if (!aiState.selectedWidgetId) {
      return emptyResult;
    }

    const selectedWidget = widgets[widgetId];
    const previousWidget = aiState.widgetsAtRequestStart?.[widgetId];

    if (
      !selectedWidget ||
      !aiState.actions ||
      !aiState.actions?.length ||
      !aiState.dataTreeChanges ||
      !previousWidget
    ) {
      return emptyResult;
    }

    const addedEventTriggers = aiState.actions
      .filter((action) => {
        const isAddTriggerAction =
          action.action === "add" &&
          action.property === propertyName &&
          action.value &&
          isPlainObject(action.value) &&
          Object.values(TriggerStepType).includes(
            ((action as AddEventAction).value as any).type as TriggerStepType,
          );

        return isAddTriggerAction;
      })
      .map((addTriggerAction) => {
        return (
          (selectedWidget?.[
            propertyName as keyof typeof selectedWidget
          ] as any[]) || []
        ).find((dataTreeTrigger: any) => {
          return (
            dataTreeTrigger.type ===
              ((addTriggerAction as AddEventAction).value as any).type &&
            equal(
              omit(dataTreeTrigger, "id"),
              (addTriggerAction as AddEventAction).value,
            )
          );
        });
      })
      .filter((trigger) => Boolean(trigger));

    const removedEventTriggers = (aiState.actions || [])
      .filter((action) => {
        const isRemoveTriggerAction =
          action.action === "remove" &&
          "property" in action &&
          action.property === propertyName &&
          "value" in action;

        return isRemoveTriggerAction;
      })
      .map((removeTriggerAction) => {
        const removeId = (removeTriggerAction as RemoveEventAction)?.value?.id;
        const propertyValue = previousWidget[propertyName as keyof WidgetProps];

        if (!removeId || !propertyValue || !Array.isArray(propertyValue)) {
          return undefined;
        }

        return propertyValue.find((trigger: any) => trigger?.id === removeId);
      })
      .filter((trigger) => Boolean(trigger));

    return {
      addedTriggers: addedEventTriggers,
      removedTriggers: removedEventTriggers,
    };
  },
);

export const selectAddedRemovedProperty = createSelector(
  [
    selectAiState,
    getWidgets,
    (_state: AppState, widgetId: string) => widgetId,
    (_state: AppState, _widgetId?: string, propertyName?: string) =>
      propertyName,
  ],
  (
    aiState,
    widgets,
    widgetId,
    propertyName,
  ):
    | {
        tag: "added" | "removed" | undefined;
        originalValue: any;
      }
    | undefined => {
    if (!widgetId || !propertyName || !aiState.actions) {
      return undefined;
    }

    const selectedWidget = widgets[widgetId];
    if (!selectedWidget) {
      return undefined;
    }

    if (
      aiState.actions.some(
        (action) => action.action === "add" && action.property === propertyName,
      )
    ) {
      return {
        tag: "added",
        originalValue: null,
      };
    }

    if (
      aiState.actions.some(
        (action) =>
          action.action === "remove" &&
          "property" in action &&
          action.property === propertyName,
      )
    ) {
      return {
        tag: "removed",
        originalValue: selectedWidget[propertyName as keyof WidgetProps],
      };
    }

    return undefined;
  },
);

export const selectAddedUpdatedRemovedChildren = createSelector(
  [selectAiState, getWidgets, (_state: AppState, widgetId: string) => widgetId],
  (
    aiState,
    widgets,
    widgetId,
  ): {
    addedChildren: WidgetProps[];
    updatedChildren: WidgetProps[];
    removedChildren: WidgetProps[];
  } => {
    const emptyResult = {
      addedChildren: [],
      updatedChildren: [],
      removedChildren: [],
    };
    const editedWidget = widgetId ? widgets[widgetId] : undefined;

    const widgetsAtRequestStart = aiState.widgetsAtRequestStart;

    if (
      !widgetsAtRequestStart ||
      !aiState.selectedWidgetId ||
      !editedWidget ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const originalChildren = widgetsAtRequestStart[widgetId]?.children || [];

    const editedChildren = (editedWidget?.children || []) as any[];

    const addedChildren = editedChildren
      .filter((childId: string) => !originalChildren.includes(childId))
      .map((childId: string) => widgets?.[childId])
      .filter(Boolean) as unknown as WidgetProps[];

    const updatedChildren = aiState.actions
      .filter(
        (action) =>
          action.action === "set" &&
          "tabs_tab" in action &&
          action.tabs_tab != null,
      )
      .map((action) => {
        const selectedTabWidget = widgetsAtRequestStart[
          editedWidget.widgetId
        ] as TabsWidgetProps<TabContainerWidgetProps>;
        const tabIndex = parseInt((action as SetTabAction).tabs_tab, 10);
        const tab = selectedTabWidget.tabs[tabIndex];
        return tab?.widgetId ? widgets[tab.widgetId] : undefined;
      })
      .filter(Boolean) as WidgetProps[];

    const removedChildren = originalChildren
      .filter((child: string) => !editedChildren.includes(child))
      .map((child: string) => widgetsAtRequestStart[child]);

    return {
      addedChildren,
      updatedChildren,
      removedChildren,
    };
  },
);

export const selectAddedUpdatedRemovedNestedChildListItems = createSelector(
  [selectAiState, getWidgets, (_state: AppState, widgetId: string) => widgetId],
  (aiState, widgets, widgetId) => {
    const emptyResult = {
      addedItems: [],
      removedItems: [],
      updatedItems: [],
    };
    const editedWidget = widgetId ? widgets[widgetId] : undefined;
    const widgetsAtRequestStart = aiState.widgetsAtRequestStart;

    if (
      !widgetsAtRequestStart ||
      !aiState.selectedWidgetId ||
      !editedWidget ||
      !aiState.actions?.length
    ) {
      return emptyResult;
    }

    const selectedWidget = widgets[aiState.selectedWidgetId];
    if (!selectedWidget || selectedWidget.type !== WidgetTypes.MENU_WIDGET) {
      return emptyResult;
    }
    const originalMenuItems =
      (widgetsAtRequestStart[widgetId] as any).manualChildren || [];
    const editedMenuItems = (editedWidget as any).manualChildren || [];

    const addedItems: { index: number; child: ManualMenuItem }[] =
      editedMenuItems
        .map((child: ManualMenuItem, index: number) => ({
          index,
          child,
        }))
        .filter(({ child }: { child: ManualMenuItem }) => {
          return !originalMenuItems.some(
            (ogChild: ManualMenuItem) =>
              getMenuItemId(ogChild) === getMenuItemId(child),
          );
        });

    const removedItems: { child: ManualMenuItem }[] = originalMenuItems
      .filter((ogChild: ManualMenuItem) => {
        return !editedMenuItems.some(
          (child: ManualMenuItem) =>
            getMenuItemId(ogChild) === getMenuItemId(child),
        );
      })
      .map((child: ManualMenuItem) => ({ child }));

    const updatedItems: { index: number; child: ManualMenuItem }[] =
      editedMenuItems
        .map((child: ManualMenuItem, index: number) => ({
          index,
          child,
        }))
        .filter(({ child }: { child: ManualMenuItem }) => {
          return originalMenuItems.some(
            (ogChild: ManualMenuItem) =>
              getMenuItemId(ogChild) === getMenuItemId(child) &&
              !equal(ogChild, child),
          );
        });
    return {
      addedItems,
      removedItems,
      updatedItems,
    };
  },
);

export const selectAiChangedProperties = createSelector(
  [selectAiState, (_state: AppState, widgetId?: string) => widgetId],
  (aiState, widgetId) => {
    if (
      !aiState.selectedWidgetId ||
      aiState.selectedWidgetId !== widgetId ||
      !aiState.dataTreeChanges
    ) {
      return [];
    }
    return aiState.changedKeys?.[widgetId] || [];
  },
);
