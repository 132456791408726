import {
  ApplicationScope,
  Dimension,
  getNextEntityName,
  RouteDef,
  WidgetTypes,
  VariableType,
  VariableMode,
  BaseBlock,
  ParallelConfig,
  ConditionalConfig,
  LoopConfig,
  TryCatchConfig,
  VariablesConfig,
  StreamConfig,
} from "@superblocksteam/shared";
import { set, get, uniq, uniqBy, isPlainObject, isArray, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { createNameValidator } from "hooks/store/useEntityNameValidator";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { CanvasLayout, PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import WidgetConfigResponse from "legacy/mockResponses/WidgetConfigResponse";
import { ItemWithPropertiesType } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import {
  flattenObject,
  unflattenObject,
} from "legacy/pages/Editor/PropertyPane/widgetPropertyPaneConfigUtils";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { GeneratedTheme } from "legacy/themes";
import {
  getWidgetDynamicPropertyPathList,
  mergeUpdatesWithBindingsOrTriggers,
} from "legacy/utils/DynamicBindingUtils";
import { getWidgetTypeFromSimplifiedType } from "legacy/utils/ai";
import { generateReactKey } from "legacy/utils/generators";
import { WidgetProps } from "legacy/widgets";
import { CanvasWidgetsReduxState } from "legacy/widgets/Factory";
import { TableWidgetProps } from "legacy/widgets/TableWidget/TableWidgetConstants";
import { ApiDto } from "store/slices/apis/types";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { AppState } from "store/types";
import { fastClone } from "utils/clone";
import { findClosestCanvas } from "utils/paste";
import { Flag, Flags } from "../featureFlags";
import { getColumnIdFromAi, getKvPropertyIdFromAi } from "./columnUtils";
import { getEventHandlers } from "./eventHandlers";
import {
  removeKvItem,
  addKvItem,
  updateKvItem,
  updatePropertiesOrder,
} from "./keyValueUtils";
import {
  addMenuItem,
  initializeMenuMetadata,
  removeMenuItem,
  updateMenuItem,
} from "./menuUtils";
import {
  addSectionColumn,
  initializeSectionColumnMetadata,
  removeSectionColumn,
  updateSectionColumn,
} from "./sectionUtils";
import {
  addTab,
  initializeTabMetadata,
  removeTab,
  updateTab,
} from "./tabUtils";
import {
  addColumns,
  removeColumns,
  updateColumnOrder,
  updateColumns,
} from "./tableUtils";
import {
  AddEventActionValue,
  AddColumnEventAction,
  ClarkComponentAction,
  ClarkAction,
  ClarkCreateApiAction,
  ColumnAction,
  DiscardedEdit,
  EditMetadata,
  KvAction,
  MenuItemAction,
  RemoveColumnEventAction,
  RemoveEventAction,
  RemoveTabAction,
  RemoveTabPropertyAction,
  SetColumnAction,
  SetTabAction,
  TabAction,
  ClarkEditApiAction,
  ClarkActionFuntions,
  SectionColumnAction,
  ClarkScaffoldApiAction,
} from "./types";
import { sanitizeEdits } from "./utils";

type ProcessingContext = {
  clarkRequestId: string;
  dataTree: DataTree;
  routes: RouteDef[];
  nameValidator: ReturnType<typeof createNameValidator>;
  featureFlags: Flags;
  theme: GeneratedTheme;
  canvasWidgets: Record<string, Partial<WidgetProps>>;
  apisV2: Record<string, ApiDtoWithPb>;
  selectedWidgetId: string;
  appMode: APP_MODE;
  dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets>;
  widgetsRuntime: CanvasWidgetsReduxState;
  widgetMetaProps: WidgetMetadata;
  evaluatedWidgets: Record<string, DataTreeWidget>;
  previousAiWidgets: Record<string, DataTreeWidget>;
  applicationId: string;
  organizationId: string;
  pageId: string;
};

export type ClarkProcessorResult = {
  changedKeys?: Record<string, string[]>;
  dataTreeChanges: Record<string, Record<string, unknown> | null>;
  widgetRenamesByWidgetId?: Record<string, string>;
  discardedEdits: Record<string, DiscardedEdit[]>;
  metadataByWidgetId?: Record<string, EditMetadata | undefined>;
  apiChanges?: Record<string, ApiDtoWithPb>;
  createdApiNamesToIds?: Record<string, string>;
  changedApiBlockPaths?: Record<string, string[]>;
};

const ORDER_KEYS: Record<
  string,
  {
    updater: (args: {
      orderFromAi: string[];
      existingOrder: string[];
      originalItems: Record<string, any>;
    }) => string[];
    originalItemsKey: string;
  }
> = {
  columnOrder: {
    updater: updateColumnOrder,
    originalItemsKey: "primaryColumns",
  },
  propertiesOrder: {
    updater: updatePropertiesOrder,
    originalItemsKey: "properties",
  },
};

interface ItemHandlerProps<T> {
  action: T;
  itemId: string | number;
  itemExists: boolean;
  canvasWidget: Partial<WidgetProps>;
  widgetId: string;
  itemCount: number;
}

type ItemIdAndExistence =
  | {
      itemId: string;
      type: "column";
      exists: boolean;
    }
  | {
      itemId: number;
      type: "menu_item";
      exists: boolean;
    }
  | {
      itemId: number;
      type: "tab";
      exists: boolean;
    }
  | {
      itemId: string;
      type: "key_value_property";
      exists: boolean;
    }
  | {
      itemId: number;
      type: "section_column";
      exists: boolean;
    }
  | {
      itemId: undefined;
      type: undefined;
      exists: false;
    };

const METADATA_INITIALIZERS: Partial<
  Record<WidgetTypes, (widget: Partial<WidgetProps>) => EditMetadata>
> = {
  [WidgetTypes.TABS_WIDGET]: initializeTabMetadata,
  [WidgetTypes.MENU_WIDGET]: initializeMenuMetadata,
  [WidgetTypes.SECTION_WIDGET]: initializeSectionColumnMetadata,
};

const WIDGETS_WITH_PAGE_PARENT: string[] = [
  WidgetTypes.MODAL_WIDGET,
  WidgetTypes.SLIDEOUT_WIDGET,
];

export type ClarkApiBlockSpec = {
  name: string;
  shape: string;
  block: ClarkApiControlBlockSpec | ClarkApiStepBlockSpec;
};

// this almost follows the BaseBlock type, but it wraps all block configs in a "block" property
type ClarkApiControlBlockSpec = {
  name: string;
  shape: string;
  block: {
    [key: string]: unknown;
  };
};

type ClarkApiStepBlockSpec = {
  integration: {
    name: string;
    type: string;
    instruction: string;
  };
};

/**
 * ClarkProcessor handles AI-driven modifications to widget properties.
 *
 * This class processes changes requested by AI actions and applies them to the widget tree.
 * It tracks specific paths that were modified and maintains metadata needed for
 * complex widget modifications like tabs, menu items, and table columns.
 *
 */

export class ClarkProcessor {
  private _clarkRequestId: string;
  private dataTreeChanges: Record<string, Record<string, unknown> | null>;
  private changedKeys: Record<string, string[]>;
  private changedApiBlockPaths: Record<string, string[]>;
  private renamesByWidgetId: Record<string, string>;
  private discardedEdits: Record<string, DiscardedEdit[]>;
  private metadataByWidgetId: Record<string, EditMetadata | undefined>;
  private apiChanges: Record<ApiDto["id"], ApiDtoWithPb>;
  private processedActions: Set<string>;

  // Nested structure for storing widget changes during processing
  private nestedChangesByWidgetId: Record<
    string,
    Record<string, unknown> | null
  >;

  private context!: ProcessingContext;
  private createdWidgetNamesToIds: Record<string, string>;
  private createdApiNamesToIds: Record<string, string>;
  private allEntityNames: string[];

  private createComponentFn: ClarkActionFuntions["createComponentFn"];
  private updateComponentFn: ClarkActionFuntions["updateComponentFn"];
  private deleteComponentFn: ClarkActionFuntions["deleteComponentFn"];
  private deleteComponentPropertiesFn: ClarkActionFuntions["deleteComponentPropertiesFn"];
  private renameEntityFn: ClarkActionFuntions["renameEntityFn"];
  private createApiFn: ClarkActionFuntions["createApiFn"];
  private updateApiFn: ClarkActionFuntions["updateApiFn"];
  private addSectionColumnFn: ClarkActionFuntions["addSectionColumnFn"];

  private getState: () => AppState;

  public get clarkRequestId(): string {
    return this._clarkRequestId;
  }

  private updateContextFromRedux() {
    this.context.canvasWidgets = this.getState().legacy.entities.canvasWidgets;
    this.context.apisV2 = this.getState().apisV2.entities as Record<
      string,
      ApiDtoWithPb
    >;
  }

  constructor(
    context: ProcessingContext,
    getState: () => AppState,
    actionFunctions: ClarkActionFuntions,
  ) {
    this._clarkRequestId = context.clarkRequestId;
    this.context = context;
    this.getState = getState;
    this.dataTreeChanges = {};
    this.changedKeys = {};
    this.renamesByWidgetId = {};
    this.discardedEdits = {};
    this.metadataByWidgetId = {};
    this.nestedChangesByWidgetId = {};
    this.context = context;
    this.createdWidgetNamesToIds = {};
    this.createdApiNamesToIds = {};
    this.apiChanges = {}; // apiId -> apiSpec
    this.changedApiBlockPaths = {}; // apiId -> path, eg: "blocks.{blockName}.propertyName"
    this.processedActions = new Set();
    this.allEntityNames = [
      ...Object.values(context.canvasWidgets).map(
        (widget) => widget.widgetName as string,
      ),
      ...Object.keys(context.dataTree),
      ...Object.values(context.apisV2).map((api) => api.apiPb?.metadata.name),
    ];

    this.createComponentFn = actionFunctions.createComponentFn;
    this.updateComponentFn = actionFunctions.updateComponentFn;
    this.deleteComponentFn = actionFunctions.deleteComponentFn;
    this.deleteComponentPropertiesFn =
      actionFunctions.deleteComponentPropertiesFn;
    this.renameEntityFn = actionFunctions.renameEntityFn;
    this.createApiFn = actionFunctions.createApiFn;
    this.updateApiFn = actionFunctions.updateApiFn;
    this.addSectionColumnFn = actionFunctions.addSectionColumnFn;
  }

  public getResult(): ClarkProcessorResult {
    // Don't return direct references as redux can freeze these objects
    return fastClone({
      dataTreeChanges: this.dataTreeChanges,
      changedKeys: this.changedKeys,
      widgetRenamesByWidgetId: this.renamesByWidgetId,
      discardedEdits: this.discardedEdits,
      metadataByWidgetId: this.metadataByWidgetId,
      apiChanges: this.apiChanges,
      changedApiBlockPaths: this.changedApiBlockPaths,
      createdApiNamesToIds: this.createdApiNamesToIds,
    });
  }

  public addAction(action: ClarkAction, defaultComponentName: string) {
    this.updateContextFromRedux();

    const { action: actionType, id } = action;

    if (this.processedActions.has(id)) {
      return;
    }

    this.processedActions.add(id);

    if (actionType === "completeApi") {
      // nothing to do here
      return;
    }

    if (actionType === "createApi") {
      const { apiName } = action as ClarkCreateApiAction;

      const newApiId = uuidv4();

      // we use the original api name so we can do lookups for edits later
      this.createdApiNamesToIds[apiName] = newApiId;

      let apiNameToUse = apiName;
      if (this.allEntityNames.includes(apiNameToUse)) {
        apiNameToUse = getNextEntityName(apiNameToUse, this.allEntityNames);
      }

      this.allEntityNames.push(apiNameToUse);

      const newApiV2 = this.createApiChanges({
        apiId: newApiId,
        apiName: apiNameToUse,
        blocks: [],
      });

      this.createApiFn(newApiV2);

      return;
    }

    if (actionType === "scaffoldApi") {
      // should already exist inside of context as an existing api
      // or inside the createdApiNamesToIds from createApi
      const { apiName: apiNameFromAction, apiSpec } =
        action as ClarkScaffoldApiAction;

      let apiId = this.createdApiNamesToIds[apiNameFromAction];
      let apiName: string = apiNameFromAction;

      let api = Object.values(this.context.apisV2).find((api) => {
        if (apiId) {
          return api.apiPb?.metadata.id === apiId;
        }

        return api.apiPb?.metadata.name === apiName;
      });

      if (!api) {
        api = this.apiChanges[apiId];
      }

      apiId = api.apiPb?.metadata.id;
      apiName = api.apiPb?.metadata.name;

      const updatedApiV2 = this.createApiChanges({
        apiId,
        apiName,
        blocks: apiSpec as any,
      });
      this.updateApiFn(updatedApiV2);
      return;
    }

    if (actionType === "editApi") {
      const { apiName, stepName, stepEdit } = action as ClarkEditApiAction;
      // We assume the api has been created and is redux
      // at this point, and so is available in the context
      // otherwise, it should be in the createdApiNamesToIds
      const apiIdForLookup = this.createdApiNamesToIds[apiName];

      const apiId: string | undefined = Object.values(this.context.apisV2).find(
        (api) => {
          if (apiIdForLookup) {
            return api.apiPb?.metadata.id === apiIdForLookup;
          }

          return api.apiPb?.metadata.name === apiName;
        },
      )?.apiPb?.metadata?.id;

      if (!apiId) {
        console.error(`Api not found for api with name: ${apiName}`);
        return;
      }

      const api = this.context.apisV2[apiId];

      if (!api) {
        throw new Error(`Api not found for api id: ${apiId}`);
      }

      // Sync current api to apiChanges. cloning to unfreeze any nested objects
      this.apiChanges[apiId] = fastClone(this.apiChanges[apiId] ?? api);
      this.findAndUpdateApiBlock(apiId, stepName, stepEdit);
      this.changedApiBlockPaths[apiId] = [
        ...(this.changedApiBlockPaths[apiId] ?? []),
        `blocks.${stepName}.${stepEdit.property}`,
      ];
      this.updateApiFn(this.apiChanges[apiId]);
      return;
    }

    // Handle
    if (actionType === "createComponent") {
      const widgetType = getWidgetTypeFromSimplifiedType(action.componentType);

      let parentId: string | undefined;
      if (WIDGETS_WITH_PAGE_PARENT.includes(widgetType)) {
        parentId = PAGE_WIDGET_ID;
      } else {
        parentId = findClosestCanvas(
          this.context.selectedWidgetId,
          this.context.canvasWidgets as CanvasWidgetsReduxState,
        )?.widgetId;
      }

      if (!parentId) {
        console.error(
          `Could not create widget ${action.componentName} because the selected component can't have children`,
        );
        return;
      }
      const newComponentId = generateReactKey();

      // Initialize the new widget plus any children
      // into nestedChangesByWidgetId
      this.createDefaultWidgetAndChildWidgets(
        newComponentId,
        widgetType as WidgetTypes,
        action.componentName,
        parentId,
      );

      const parentWidget =
        this.nestedChangesByWidgetId[parentId] ??
        this.context.canvasWidgets[parentId];

      // Update the parent's children array
      const updatedChildren = [
        ...((parentWidget?.children as Array<string>) ?? []),
        newComponentId,
      ];

      // Create or update parent widget structure
      if (!this.nestedChangesByWidgetId[parentId]) {
        this.nestedChangesByWidgetId[parentId] = {};
      }

      set(
        this.nestedChangesByWidgetId[parentId] as Record<string, unknown>,
        "children",
        updatedChildren,
      );

      if (!this.changedKeys[parentId]) {
        this.changedKeys[parentId] = [];
      }
      this.changedKeys[parentId].push("children");
      this.createdWidgetNamesToIds[action.componentName] = newComponentId;

      // Dispatch the create
      this.createComponentFn({
        parentWidgetId: parentId,
        widgetType: widgetType as WidgetTypes,
        newWidgetId: newComponentId,
        initialProps: fastClone(
          this.nestedChangesByWidgetId[newComponentId],
        ) as Partial<WidgetProps>,
      });
      this.renamesByWidgetId[newComponentId] = action.componentName;
    } else if (actionType === "removeComponent") {
      // get the widget id
      const existingWidget = Object.values(this.context.canvasWidgets).find(
        (w) => w.widgetName === action.componentName,
      );

      if (!existingWidget) {
        console.error(
          `Cannot remove widget. Widget with name "${action.componentName}" not found in dataTree`,
        );
        return;
      }

      const widgetId = existingWidget.widgetId as string;
      const parentId = existingWidget.parentId as string;

      this.nestedChangesByWidgetId[widgetId] = null;

      // and remove from the parent children
      const prevParentWidget =
        this.nestedChangesByWidgetId[parentId] ??
        this.context.canvasWidgets[parentId];

      // Update the parent's children array
      const updatedChildren = (
        prevParentWidget?.children as Array<string>
      ).filter((child) => child !== widgetId);

      // Create or update parent widget structure
      if (!this.nestedChangesByWidgetId[parentId]) {
        this.nestedChangesByWidgetId[parentId] = {};
      }

      set(
        this.nestedChangesByWidgetId[parentId] as Record<string, unknown>,
        "children",
        updatedChildren,
      );

      if (!this.changedKeys[parentId]) {
        this.changedKeys[parentId] = [];
      }
      this.changedKeys[parentId].push("children");

      // Dispatch the delete
      this.deleteComponentFn({
        widgetId,
      });
    } else {
      // Not create or remove, so it must be an edit
      const componentName = action.componentName ?? defaultComponentName;
      let dataTreeWidget = this.context.dataTree[ApplicationScope.PAGE][
        componentName
      ] as Partial<WidgetProps>;

      if (this.createdWidgetNamesToIds[componentName]) {
        const createdWidgetId = this.createdWidgetNamesToIds[componentName];
        dataTreeWidget = this.nestedChangesByWidgetId[
          createdWidgetId
        ] as Partial<WidgetProps>;
      }

      if (!dataTreeWidget.widgetId) {
        throw new Error(`Widget with name "${componentName}" has no widgetId`);
      }
      const widgetId = dataTreeWidget.widgetId;

      const canvasWidget =
        this.context.canvasWidgets[widgetId] ??
        // this case handles widgets that were created by AI within this session
        this.nestedChangesByWidgetId[widgetId];

      if (!this.nestedChangesByWidgetId[widgetId]) {
        this.nestedChangesByWidgetId[widgetId] = {};
      }
      if (!this.discardedEdits[widgetId]) {
        this.discardedEdits[widgetId] = [];
      }
      if (!this.changedKeys[widgetId]) {
        this.changedKeys[widgetId] = [];
      }
      const { items, itemCount } = this.getItems(canvasWidget);

      if (
        !this.metadataByWidgetId[widgetId] &&
        METADATA_INITIALIZERS[canvasWidget.type as WidgetTypes]
      ) {
        this.metadataByWidgetId[widgetId] =
          METADATA_INITIALIZERS[
            canvasWidget.type as keyof typeof METADATA_INITIALIZERS
          ]?.(canvasWidget);
      }

      const {
        itemId,
        exists: itemExists,
        type: itemType,
      } = this.getItemIdAndExistence({
        action,
        widget: canvasWidget,
        metadata: this.metadataByWidgetId[widgetId],
        items,
        changes: this.nestedChangesByWidgetId[widgetId]!,
      });
      const valueFromAi = "value" in action ? action.value : undefined;
      const propertyName = this.getPropertyName(action, canvasWidget.type);

      // Use lodash get to safely access the nested property
      const previousValue = propertyName
        ? ((this.nestedChangesByWidgetId[widgetId]
            ? get(this.nestedChangesByWidgetId[widgetId], propertyName)
            : undefined) ?? canvasWidget[propertyName as keyof WidgetProps])
        : undefined;

      if (itemType) {
        // handle changes to tabs, columns, menu items, key value properties
        switch (itemType) {
          case "tab":
            this.handleTabActions({
              action: action as TabAction,
              itemId,
              itemExists,
              canvasWidget,
              widgetId,
              itemCount,
            });
            break;
          case "section_column":
            this.handleSectionColumnActions({
              action: action as SectionColumnAction,
              itemId,
              itemExists,
              canvasWidget,
              widgetId,
              itemCount,
            });
            break;
          case "column":
            this.handleColumnActions({
              action: action as ColumnAction,
              itemId,
              itemExists,
              canvasWidget,
              widgetId,
              itemCount,
            });
            break;
          case "menu_item":
            this.handleMenuItemActions({
              action: action as MenuItemAction,
              itemId,
              itemExists,
              canvasWidget,
              widgetId,
              itemCount,
            });
            break;
          case "key_value_property":
            this.handleKvActions({
              action: action as KvAction,
              itemId,
              itemExists,
              canvasWidget,
              widgetId,
              itemCount,
            });
            break;
        }
      } else {
        switch (actionType) {
          case "set":
            if (ORDER_KEYS[propertyName]) {
              const { updater, originalItemsKey } = ORDER_KEYS[propertyName];
              const existingOrder: string[] = this.nestedChangesByWidgetId[
                widgetId
              ]
                ? get(this.nestedChangesByWidgetId[widgetId], propertyName)
                : (canvasWidget as any)[propertyName];
              const newOrder = updater({
                orderFromAi: valueFromAi as string[],
                existingOrder: existingOrder as string[],
                originalItems: canvasWidget[
                  originalItemsKey as keyof WidgetProps
                ] as Record<string, any>,
              });
              this.setNestedChangeForWidget(widgetId, propertyName, newOrder);
            } else if (propertyName === "widgetName") {
              if (typeof valueFromAi === "string") {
                const val = valueFromAi.replaceAll(" ", "_");
                const nameError = this.context.nameValidator({
                  currentName: canvasWidget.widgetName as string,
                  name: val,
                  scope: ApplicationScope.PAGE,
                });
                if (!nameError) {
                  this.renamesByWidgetId[widgetId] = val;
                  this.setNestedChangeForWidget(widgetId, propertyName, val);
                  this.renameEntityFn({
                    entityId: widgetId,
                    oldName: canvasWidget.widgetName as string,
                    newName: val,
                  });
                }
              }
            } else {
              this.setNestedChangeForWidget(
                widgetId,
                propertyName,
                valueFromAi,
              );
            }
            break;
          case "add":
            this.setNestedChangeForWidget(
              widgetId,
              propertyName,
              getEventHandlers({
                prevValue: previousValue,
                value: valueFromAi as
                  | AddEventActionValue
                  | AddEventActionValue[],
                dataTree: this.context.dataTree,
                routes: this.context.routes,
              }),
            );
            break;
          case "remove":
            if (valueFromAi != null) {
              const idToRemove = (valueFromAi as RemoveEventAction["value"])
                ?.id;
              if (Array.isArray(previousValue)) {
                this.setNestedChangeForWidget(
                  widgetId,
                  propertyName,
                  previousValue.filter((item) => item.id !== idToRemove),
                );
              }
            } else {
              this.setNestedChangeForWidget(widgetId, propertyName, undefined);
            }
            break;
          case "reset":
            this.setNestedChangeForWidget(widgetId, propertyName, undefined);
            break;
          default:
            console.error(`Unknown action type ${actionType} returned`);
        }
      }
      // Create dataTreeChanges for the widget
      this.createDataTreeChangesForWidget(widgetId);
      const flattenedWidgetUpdates = flattenObject(
        fastClone(this.dataTreeChanges[widgetId] ?? {}),
      );
      // Dispatch the update
      this.updateComponentFn({
        widgetId,
        widgetUpdates: flattenedWidgetUpdates,
      });
    }
  }

  public createDataTreeChanges() {
    // clear out existing dataTreeChanges before we compute new ones
    this.dataTreeChanges = {};

    for (const widgetId of Object.keys(this.nestedChangesByWidgetId)) {
      this.createDataTreeChangesForWidget(widgetId);
    }
  }

  private createDataTreeChangesForWidget(widgetId: string) {
    // clear out existing dataTreeChanges before we compute new ones
    if (!this.dataTreeChanges) {
      this.dataTreeChanges = {};
    }
    this.dataTreeChanges[widgetId] = {};

    let existingWidget = this.context.canvasWidgets[widgetId];

    if (this.nestedChangesByWidgetId[widgetId] === null) {
      // Widget was deleted, use null to indicate deletion
      this.dataTreeChanges[widgetId] = null;

      // also remove the widget from the parent
      const parentId = existingWidget?.parentId;
      if (parentId) {
        const parentWidget = this.context.canvasWidgets[parentId];
        if (parentWidget) {
          // Use the nestedChangesByWidgetId given there may be a pending change to the parent's children
          const parentChildren = (this.nestedChangesByWidgetId[parentId]
            ?.children ??
            parentWidget.children ??
            []) as string[];
          const newParentChildren = parentChildren.filter(
            (child) => child !== widgetId,
          );

          this.dataTreeChanges[parentId] = {
            ...this.dataTreeChanges[parentId],
            children: newParentChildren,
          };
        }
      }

      // nothing else to do for deletions
      return;
    }

    let widgetIsNew = false;
    // Widget was created (either explicitly by AI or implicitly by adding a tab, section col, etc.)
    if (!existingWidget) {
      widgetIsNew = true;
      this.createNewWidgetInDataTree(widgetId);
      existingWidget = this.dataTreeChanges[widgetId] as Partial<WidgetProps>;
    } else {
      this.dataTreeChanges[widgetId] = {};
    }

    const propSections = getItemPropertyPaneConfig(
      existingWidget.type as ItemWithPropertiesType,
    );
    const allProperties = propSections
      .flatMap((section) => section.children)
      .filter(Boolean);

    // Get changed keys from our modified paths tracking
    this.changedKeys[widgetId] = this.changedKeys[widgetId] || [];

    this.discardedEdits[widgetId] = [];

    if (!widgetIsNew) {
      for (const [topLevelKey, changeSetObj] of Object.entries(
        this.nestedChangesByWidgetId?.[widgetId] ?? {},
      )) {
        const shouldCopyProperty =
          isArray(changeSetObj) || isPlainObject(changeSetObj);

        if (
          !this.dataTreeChanges?.[widgetId]?.[topLevelKey] &&
          shouldCopyProperty &&
          !this.changedKeys[widgetId].includes(topLevelKey)
        ) {
          set(
            this.dataTreeChanges,
            `${widgetId}.${topLevelKey}`,
            fastClone(existingWidget[topLevelKey as keyof WidgetProps]),
          );
        }
      }
    }

    // Copy our nested changes directly into dataTreeChanges
    if (this.nestedChangesByWidgetId[widgetId]) {
      this.dataTreeChanges[widgetId] = merge(
        this.dataTreeChanges[widgetId] ?? {},
        this.nestedChangesByWidgetId[widgetId],
      );
    }

    const temporaryDynamicProperties =
      getWidgetDynamicPropertyPathList(existingWidget);

    sanitizeEdits({
      changesByWidgetId: this.dataTreeChanges,
      properties: allProperties as PropertyPaneConfig[],
      discardedEdits: this.discardedEdits,
      dynamicProperties: temporaryDynamicProperties,
      previousWidget: existingWidget,
      theme: this.context.theme,
      featureFlags: this.context.featureFlags,
      changedKeys: this.changedKeys[widgetId],
      widgets: this.context.canvasWidgets,
    });

    set(
      this.dataTreeChanges,
      `${widgetId}.dynamicPropertyPathList`,
      uniqBy(temporaryDynamicProperties, (prop) => prop.key),
    );

    // update dynamic properties
    this.dataTreeChanges[widgetId] = mergeUpdatesWithBindingsOrTriggers(
      existingWidget,
      propSections,
      flattenObject(this.dataTreeChanges[widgetId] ?? {}),
      this.context.featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );
    this.dataTreeChanges[widgetId] = unflattenObject(
      this.dataTreeChanges[widgetId] ?? {},
    );

    this.changedKeys[widgetId] = uniq(
      (this.changedKeys[widgetId] ?? []).filter((key) => {
        if (
          this.discardedEdits[widgetId].some(
            (edit) => edit.propertyName === key,
          )
        ) {
          return false;
        }
        return true;
      }),
    );

    // Ensure widgetName is unique
    const widgetName = this.dataTreeChanges[widgetId]?.widgetName as
      | string
      | undefined;
    if (
      widgetName &&
      this.dataTreeChanges[widgetId] &&
      this.allEntityNames.includes(widgetName)
    ) {
      const newWidgetName = getNextEntityName(widgetName, this.allEntityNames);
      (this.dataTreeChanges[widgetId] as Record<string, unknown>).widgetName =
        newWidgetName;
      this.allEntityNames.push(newWidgetName);
    }

    if (this.widgetHasNoChanges(widgetId)) {
      delete this.dataTreeChanges[widgetId];
      delete this.changedKeys[widgetId];
      return;
    }
  }

  private createApiChanges({
    apiId,
    apiName,
    blocks,
  }: {
    apiId: string;
    apiName: string;
    blocks: ApiDtoWithPb["apiPb"]["blocks"];
  }) {
    const apiMetadata = {
      id: apiId,
      name: apiName,
      organization: this.context.organizationId,
      application: this.context.applicationId,
      pageId: this.context.pageId,
      // add others: environment etc.?
    };
    this.apiChanges[apiId] = {
      id: apiId,
      name: apiName,
      applicationId: this.context.applicationId,
      organizationId: this.context.organizationId,
      // triggerType these seem unnecessary?
      triggerType: 0, // 0 === application
      apiPb: {
        metadata: apiMetadata,
        blocks: (blocks ?? []).map((step: any) => {
          return ClarkProcessor.createApiBlockFromClarkBlock(step);
        }),
        trigger: {
          application: {
            id: this.context.applicationId,
            pageId: this.context.pageId,
          },
        },
      },
    };

    return this.apiChanges[apiId];
  }

  // blocks getting updated here are in apiChanges, so
  // they're in the ApiDtoWithPb structure, not the structure of blocks in the clark actions
  // which need some massaging to get into the ApiDtoWithPb structure
  private findAndUpdateApiBlock(
    apiId: string,
    stepName: string,
    stepEdit: {
      property: string;
      value: unknown;
      action: "set";
    },
  ) {
    const getStepBodyKey = (
      obj: Record<string, unknown>,
    ): string | undefined => {
      for (const key in obj) {
        if (key !== "integration") return key;
      }
    };

    const blocks = this.apiChanges[apiId].apiPb.blocks ?? [];
    const path = ClarkProcessor.findStepPath(blocks, stepName);
    if (path) {
      const dottedPath = path.join(".");
      let fullPath = `${apiId}.apiPb.blocks.${dottedPath}`;
      const existingBlock: any = get(this.apiChanges, fullPath);
      const blockType = ClarkProcessor.getBlockType(existingBlock);
      const stepBodyKey = getStepBodyKey(existingBlock[blockType]);
      if (stepBodyKey) {
        fullPath += `.step.${stepBodyKey}.${stepEdit.property}`;
      } else {
        fullPath += `.${stepEdit.property}`;
      }
      if (!stepBodyKey) {
        throw new Error("No step body key found");
      }
      set(this.apiChanges, fullPath, stepEdit.value);
    }
  }

  static findStepPath(
    rootBlock: any,
    targetName: string,
    currentPath: Array<string | number> = [],
  ): Array<string | number> | null {
    if (rootBlock.name === targetName) {
      return currentPath;
    }

    // If we're looking at a top-level array of steps
    if (Array.isArray(rootBlock) || rootBlock.blocks) {
      const hasBlocksKey = rootBlock.blocks ? true : false;
      const blocksToLoop = hasBlocksKey ? rootBlock.blocks : rootBlock;

      for (let i = 0; i < blocksToLoop.length; i++) {
        const nestedPath = [
          ...currentPath,
          ...(hasBlocksKey ? ["blocks", i] : [i]),
        ];
        const result = ClarkProcessor.findStepPath(
          blocksToLoop[i],
          targetName,
          nestedPath,
        );
        if (result) return result;
      }
    }

    const blockType = ClarkProcessor.getBlockType(rootBlock);
    // If this is an integration block, it doesn't have nested blocks, just give up
    if (blockType === "step") {
      return null;
    } else {
      // Handle control blocks that can contain nested blocks
      const blockBody = rootBlock[blockType];

      switch (blockType) {
        case "parallel": {
          if (blockBody.static) {
            // Check static paths
            for (const path of Object.keys(blockBody.static.paths)) {
              const nestedPath = [
                ...currentPath,
                blockType,
                "static",
                "paths",
                path,
                "blocks",
                0,
              ];
              const result = ClarkProcessor.findStepPath(
                blockBody.static.paths[path].blocks[0],
                targetName,
                nestedPath,
              );
              if (result) return result;
            }
          } else if (blockBody.dynamic) {
            // Check dynamic blocks
            for (let i = 0; i < blockBody.dynamic.blocks.length; i++) {
              const nestedPath = [
                ...currentPath,
                blockType,
                "dynamic",
                "blocks",
                i,
              ];
              const result = ClarkProcessor.findStepPath(
                blockBody.dynamic.blocks[i],
                targetName,
                nestedPath,
              );
              if (result) return result;
            }
          }
          break;
        }
        case "conditional": {
          // Check if block
          if (blockBody.if) {
            const nestedPath = [...currentPath, blockType, "if"];
            const result = ClarkProcessor.findStepPath(
              blockBody.if,
              targetName,
              nestedPath,
            );
            if (result) return result;
          }

          // Check elseIf blocks
          if (blockBody.elseIf) {
            for (let i = 0; i < blockBody.elseIf.length; i++) {
              // Check blocks within each elseIf
              for (let j = 0; j < blockBody.elseIf[i].blocks.length; j++) {
                const nestedPath = [
                  ...currentPath,
                  blockType,
                  "elseIf",
                  i,
                  "blocks",
                  j,
                ];
                const result = ClarkProcessor.findStepPath(
                  blockBody.elseIf[i].blocks[j],
                  targetName,
                  nestedPath,
                );
                if (result) return result;
              }
            }
          }

          // Check else blocks
          if (blockBody.else) {
            for (let i = 0; i < blockBody.else.blocks.length; i++) {
              const nestedPath = [
                ...currentPath,
                blockType,
                "else",
                "blocks",
                i,
              ];
              const result = ClarkProcessor.findStepPath(
                blockBody.else.blocks[i],
                targetName,
                nestedPath,
              );
              if (result) return result;
            }
          }
          break;
        }
        case "loop": {
          // Check loop blocks
          for (let i = 0; i < blockBody.blocks.length; i++) {
            const nestedPath = [...currentPath, blockType, "blocks", i];
            const result = ClarkProcessor.findStepPath(
              blockBody.blocks[i],
              targetName,
              nestedPath,
            );
            if (result) return result;
          }
          break;
        }
        case "tryCatch": {
          // Check try blocks
          for (let i = 0; i < blockBody.try.blocks.length; i++) {
            const nestedPath = [...currentPath, blockType, "try", "blocks", i];
            const result = ClarkProcessor.findStepPath(
              blockBody.try.blocks[i],
              targetName,
              nestedPath,
            );
            if (result) return result;
          }

          // Check catch blocks
          for (let i = 0; i < blockBody.catch.blocks.length; i++) {
            const nestedPath = [
              ...currentPath,
              blockType,
              "catch",
              "blocks",
              i,
            ];
            const result = ClarkProcessor.findStepPath(
              blockBody.catch.blocks[i],
              targetName,
              nestedPath,
            );
            if (result) return result;
          }

          // Check finally blocks
          for (let i = 0; i < blockBody.finally.blocks.length; i++) {
            const nestedPath = [
              ...currentPath,
              blockType,
              "finally",
              "blocks",
              i,
            ];
            const result = ClarkProcessor.findStepPath(
              blockBody.finally.blocks[i],
              targetName,
              nestedPath,
            );
            if (result) return result;
          }
          break;
        }
        case "stream": {
          // Check stream process blocks
          for (let i = 0; i < blockBody.process.blocks.length; i++) {
            const nestedPath = [
              ...currentPath,
              blockType,
              "process",
              "blocks",
              i,
            ];
            const result = ClarkProcessor.findStepPath(
              blockBody.process.blocks[i],
              targetName,
              nestedPath,
            );
            if (result) return result;
          }
          break;
        }
      }
    }

    return null;
  }

  static getBlockType(block: any) {
    if (block.conditional) {
      return "conditional";
    } else if (block.loop) {
      return "loop";
    } else if (block.tryCatch) {
      return "tryCatch";
    } else if (block.stream) {
      return "stream";
    } else if (block.parallel) {
      return "parallel";
    } else if (block.variables) {
      return "variables";
    } else if (block.break) {
      return "break";
    } else if (block.return) {
      return "return";
    } else if (block.integration || block.step) {
      // clark returns "integration" instead of "step" for step blocks
      return "step";
    } else {
      throw new Error(`Unknown block type for block ${JSON.stringify(block)}`);
    }
  }

  // the block structure is different from clark than the exact apiPb format
  // so we need to massage the block structure to match the apiPb format
  static createApiBlockFromClarkBlock(
    clarkApiBlock: ClarkApiBlockSpec,
  ): BaseBlock {
    const blockType = ClarkProcessor.getBlockType(clarkApiBlock.block);

    if (blockType === "step") {
      const block = clarkApiBlock.block as ClarkApiStepBlockSpec;
      return {
        name: clarkApiBlock.name,
        step: {
          integration: block.integration?.name?.includes("__")
            ? (block.integration?.name as string).split("__")[1]
            : block.integration?.name,
          [block.integration?.type as string]: {},
        },
      };
    } else {
      // this means it's a control block
      // they have a different shape than standard blocks
      // there's no step object, the control block name is the property object key,
      // and some can have children
      // ex:
      // {
      //   name: 'Parallel1',
      //   parallel: { ...configHere }
      // }

      const block = clarkApiBlock.block as Record<string, unknown>;
      const blockBody = fastClone(block[blockType]);

      // TODO: Unpack nested blocks inside the control blocks here

      const apiBlock = {
        name: clarkApiBlock.name,
        [blockType]: {
          ...(blockBody as Record<string, unknown>),
        },
      };

      switch (blockType) {
        case "parallel": {
          const parallelBlockBody = blockBody as ParallelConfig;

          // set default wait
          if (!parallelBlockBody.wait) {
            set(apiBlock, "parallel.wait", "WAIT_ALL");
          }

          if (parallelBlockBody.static) {
            const apiPbStaticPaths: ParallelConfig["static"] = { paths: {} };

            (parallelBlockBody.static.paths as unknown as any[]).forEach(
              (path: { name: string; blocks: ClarkApiBlockSpec[] }) => {
                apiPbStaticPaths.paths[path.name] = {
                  blocks: path.blocks.map((block: any) =>
                    ClarkProcessor.createApiBlockFromClarkBlock(block),
                  ),
                };
              },
            );

            (apiBlock.parallel as unknown as any).static.paths =
              apiPbStaticPaths.paths;
          } else if (parallelBlockBody.dynamic) {
            parallelBlockBody.dynamic = {
              ...parallelBlockBody.dynamic,
              variables: {
                item: "item",
                ...((parallelBlockBody.dynamic.variables as any) ?? {}), // variables might be undefined by clark
              },
              paths: parallelBlockBody.dynamic.paths,
              blocks: (parallelBlockBody.dynamic?.blocks ?? []).map(
                (block: any) =>
                  ClarkProcessor.createApiBlockFromClarkBlock(block),
              ),
            };
          }
          break;
        }
        case "conditional": {
          const conditionalBlockBody = blockBody as ConditionalConfig;

          if (conditionalBlockBody.if) {
            set(
              apiBlock,
              "conditional.if.blocks",
              conditionalBlockBody.if.blocks?.map((block: any) =>
                ClarkProcessor.createApiBlockFromClarkBlock(block),
              ),
            );
          } else if (
            conditionalBlockBody.elseIf &&
            conditionalBlockBody.elseIf.length > 0
          ) {
            conditionalBlockBody.elseIf.forEach((condition) => {
              condition.blocks = condition.blocks?.map((block: any) =>
                ClarkProcessor.createApiBlockFromClarkBlock(block),
              );
            });
          } else if (conditionalBlockBody.else) {
            set(
              apiBlock,
              "conditional.else.blocks",
              conditionalBlockBody.else.blocks?.map((block: any) =>
                ClarkProcessor.createApiBlockFromClarkBlock(block),
              ),
            );
          }
          break;
        }
        case "loop": {
          const loopBlockBody = blockBody as LoopConfig;

          if (!get(apiBlock, "loop.variables.item")) {
            set(apiBlock, "loop.variables.item", "item");
          }
          if (!get(apiBlock, "loop.variables.index")) {
            set(apiBlock, "loop.variables.index", "index");
          }

          set(
            apiBlock,
            "loop.blocks",
            loopBlockBody.blocks?.map((block: any) =>
              ClarkProcessor.createApiBlockFromClarkBlock(block),
            ),
          );
          break;
        }
        case "tryCatch": {
          const tryCatchBlockBody = blockBody as TryCatchConfig;

          // add the error variable to the try catch
          if (!get(apiBlock, "tryCatch.variables.error")) {
            set(apiBlock, "tryCatch.variables.error", "error");
          }

          set(
            apiBlock,
            "tryCatch.try.blocks",
            tryCatchBlockBody.try?.blocks?.map((block: any) =>
              ClarkProcessor.createApiBlockFromClarkBlock(block),
            ),
          );

          set(
            apiBlock,
            "tryCatch.catch.blocks",
            tryCatchBlockBody.catch?.blocks?.map((block: any) =>
              ClarkProcessor.createApiBlockFromClarkBlock(block),
            ),
          );

          set(
            apiBlock,
            "tryCatch.finally.blocks",
            tryCatchBlockBody.finally?.blocks?.map((block: any) =>
              ClarkProcessor.createApiBlockFromClarkBlock(block),
            ),
          );
          break;
        }
        case "variables": {
          const variablesBlockBody = blockBody as VariablesConfig;

          set(
            apiBlock,
            "variables.items",
            variablesBlockBody.items.map((clarkVariable: any) => ({
              ...clarkVariable,
              type: VariableType.SIMPLE,
              mode: VariableMode.READWRITE,
            })),
          );

          break;
        }
        case "stream": {
          const streamBlockBody = blockBody as StreamConfig;

          set(
            apiBlock,
            "stream.process.blocks",
            streamBlockBody.process?.blocks?.map((block: any) =>
              ClarkProcessor.createApiBlockFromClarkBlock(block),
            ),
          );
          break;
        }
      }

      return apiBlock;
    }
  }

  public hasResults() {
    return (
      Object.keys(this.nestedChangesByWidgetId).length > 0 ||
      Object.keys(this.apiChanges).length > 0
    );
  }

  /* utilities */
  /**
   * Sets a change for a widget property using the nested structure.
   *
   * @param widgetId - ID of the widget to modify
   * @param propertyPath - Path to the property, can be nested (e.g. "primaryColumns.column1.width")
   * @param value - Value to set at the specified path
   */
  private setNestedChangeForWidget(
    widgetId: string,
    propertyPath: string,
    value: any,
  ) {
    // Ensure widget exists in our changes map
    if (!this.nestedChangesByWidgetId[widgetId]) {
      this.nestedChangesByWidgetId[widgetId] = {};
    }

    // Track that this specific path was modified
    if (!this.changedKeys[widgetId]) {
      this.changedKeys[widgetId] = [];
    }
    this.changedKeys[widgetId].push(propertyPath);

    // Handle objects specially by cloning to prevent reference issues
    let valueToUse = value;
    if (isPlainObject(value) || isArray(value)) {
      valueToUse = fastClone(value);
    }

    // Use lodash set to handle nested paths
    set(
      this.nestedChangesByWidgetId[widgetId] as Record<string, unknown>,
      propertyPath,
      valueToUse,
    );
  }

  private setChangedKeysForNewWidget(widgetId: string) {
    this.changedKeys[widgetId] = ["widgetId", "widgetName", "type"];
  }

  private widgetHasNoChanges(widgetId: string) {
    return (
      this.changedKeys[widgetId]?.filter(
        (key) =>
          ![
            "dynamicPropertyPathList",
            "dynamicBindingPathList",
            "dynamicTriggerPathList",
          ].includes(key),
      ).length === 0
    );
  }

  private getPropertyName(
    action: ClarkComponentAction,
    widgetType: undefined | WidgetProps["type"],
  ) {
    if ("property" in action) {
      if (
        widgetType === WidgetTypes.BUTTON_WIDGET &&
        action.property === "textProps.textStyle.textColor.default"
      ) {
        return "textColor";
      }
      return action.property ?? "";
    }
    return "";
  }

  private getItems(widget: Partial<WidgetProps>) {
    switch (widget.type) {
      case WidgetTypes.MENU_WIDGET:
        return {
          items: (widget as any).manualChildren,
          itemCount: (widget as any).manualChildren.length,
        };
      case WidgetTypes.TABLE_WIDGET:
        // FIXME: once we get actin hooks working this ?? {} isn't required
        return {
          items: Object.keys((widget as any)?.primaryColumns ?? {}),
          itemCount: Object.keys((widget as any)?.primaryColumns ?? {}).length,
        };
      case WidgetTypes.TABS_WIDGET:
        return {
          items: (widget as any).tabs,
          itemCount: (widget as any).tabs.length,
        };
      case WidgetTypes.SECTION_WIDGET:
        return {
          items: (widget as any).children,
          itemCount: (widget as any).children.length,
        };
      case WidgetTypes.KEY_VALUE_WIDGET:
        return {
          items: Object.keys((widget as any)?.properties ?? {}),
          itemCount: Object.keys((widget as any)?.properties ?? {}).length,
        };
      default:
        return {
          items: [],
          itemCount: 0,
        };
    }
  }

  private getItemIdAndExistence({
    action,
    widget,
    metadata,
    items,
    changes,
  }: {
    action: ClarkComponentAction;
    widget: Partial<WidgetProps>;
    metadata: EditMetadata | undefined;
    items: Array<any>;
    changes: Record<string, unknown>;
  }): ItemIdAndExistence {
    switch (widget.type) {
      case WidgetTypes.MENU_WIDGET:
        if ("menu_item" in action && action.menu_item != null) {
          const menuItemIndex = parseInt(action.menu_item, 10);
          const existingIndices = Object.values(
            (metadata as EditMetadata).idsToIndices,
          );
          const menuItemExists =
            menuItemIndex != null &&
            metadata &&
            existingIndices.includes(menuItemIndex);
          return {
            itemId: menuItemIndex,
            exists: Boolean(menuItemExists),
            type: "menu_item",
          };
        }
        break;
      case WidgetTypes.TABLE_WIDGET:
        if ("table_column" in action && action.table_column != null) {
          const primaryColumns = ((widget as any).primaryColumns ??
            {}) as Record<string, any>;

          const column =
            getColumnIdFromAi(action.table_column, primaryColumns) ??
            action.table_column;

          const columnExists =
            column &&
            (items.includes(column) ||
              (changes.columnOrder as string[])?.includes(column));

          return {
            itemId: column,
            exists: Boolean(columnExists),
            type: "column",
          };
        }
        break;
      case WidgetTypes.TABS_WIDGET:
        if ("tabs_tab" in action && action.tabs_tab) {
          const tabIndex = parseInt(action.tabs_tab, 10);
          const existingIndices = Object.values(
            (metadata as EditMetadata).idsToIndices,
          );
          const tabExists =
            tabIndex != null && metadata && existingIndices.includes(tabIndex);
          return {
            itemId: tabIndex,
            exists: Boolean(tabExists),
            type: "tab",
          };
        }
        break;
      case WidgetTypes.KEY_VALUE_WIDGET:
        if (
          "key_value_property" in action &&
          action.key_value_property != null
        ) {
          const kvItem = getKvPropertyIdFromAi(
            action.key_value_property,
            (widget as any).properties,
          );
          const kvItemExists =
            (widget as any).properties[kvItem] != null ||
            changes[`properties.${kvItem}`] != null;
          return {
            itemId: kvItem,
            exists: Boolean(kvItemExists),
            type: "key_value_property",
          };
        }
        break;
      case WidgetTypes.SECTION_WIDGET:
        if ("section_column" in action && action.section_column != null) {
          const sectionColumnIndex = parseInt(action.section_column, 10);
          const existingIndices = Object.values(
            (metadata as EditMetadata).idsToIndices,
          );
          const columnExists =
            sectionColumnIndex != null &&
            metadata &&
            existingIndices.includes(sectionColumnIndex);
          return {
            itemId: sectionColumnIndex,
            exists: Boolean(columnExists),
            type: "section_column",
          };
        }
        break;
    }
    return {
      itemId: undefined,
      exists: false,
      type: undefined,
    };
  }

  private handleTabActions({
    action,
    itemId,
    itemExists,
    canvasWidget,
    widgetId,
  }: ItemHandlerProps<
    SetTabAction | RemoveTabAction | RemoveTabPropertyAction
  >) {
    const metadata = this.metadataByWidgetId[widgetId];
    if (metadata == null) {
      throw new Error(
        `Metadata for widget ${widgetId} not found in ClarkProcessor`,
      );
    }
    const itemIndex =
      typeof itemId === "string" ? parseInt(itemId, 10) : itemId;
    const idsToIndices = metadata.idsToIndices;
    const indicesToIds = Object.fromEntries(
      Object.entries(idsToIndices).map(([id, index]) => [index, id]),
    );
    const tabId = indicesToIds[itemIndex];

    switch (action.action) {
      case "set":
        if (itemExists && tabId != null) {
          updateTab({
            tabId,
            property: action.property as string,
            value: action.value,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeysByWidgetId: this.changedKeys ?? {},
          });
        } else if (!itemExists) {
          const newWidgetId = addTab({
            property: action.property as string,
            value: action.value,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeysByWidgetId: this.changedKeys ?? {},
            createComponentFn: this.createComponentFn,
          });
          set(
            this.metadataByWidgetId,
            `${widgetId}.idsToIndices.${newWidgetId}`,
            itemIndex,
          );
        }
        break;
      case "remove":
        if (itemExists && "property" in action) {
          updateTab({
            tabId,
            property: action.property as string,
            value: undefined,
            changesByWidgetId: this.nestedChangesByWidgetId,
            widget: canvasWidget,
            changedKeysByWidgetId: this.changedKeys ?? {},
          });
        } else if (itemExists) {
          removeTab({
            tabId,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeysByWidgetId: this.changedKeys ?? {},
            deleteComponentFn: this.deleteComponentFn,
          });
        }
        break;
    }
  }

  private handleSectionColumnActions({
    action,
    itemId,
    itemExists,
    canvasWidget,
    widgetId,
  }: ItemHandlerProps<SectionColumnAction>) {
    const metadata = this.metadataByWidgetId[widgetId];
    if (metadata == null) {
      throw new Error(
        `Metadata for widget ${widgetId} not found in ClarkProcessor`,
      );
    }
    const itemIndex =
      typeof itemId === "string" ? parseInt(itemId, 10) : itemId;
    const idsToIndices = metadata.idsToIndices;
    const indicesToIds = Object.fromEntries(
      Object.entries(idsToIndices).map(([id, index]) => [index, id]),
    );
    const sectionColumnId = indicesToIds[itemIndex];

    switch (action.action) {
      case "set":
        if (itemExists && sectionColumnId != null) {
          updateSectionColumn({
            sectionColumnId,
            property: action.property as string,
            value: action.value,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeysByWidgetId: this.changedKeys ?? {},
          });
        } else if (!itemExists) {
          const newWidgetId = addSectionColumn({
            property: action.property as string,
            value: action.value,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeysByWidgetId: this.changedKeys ?? {},
            addSectionColumnFn: this.addSectionColumnFn,
          });
          set(
            this.metadataByWidgetId,
            `${widgetId}.idsToIndices.${newWidgetId}`,
            itemIndex,
          );
        }
        break;
      case "remove":
        if (itemExists) {
          removeSectionColumn({
            sectionColumnId,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeysByWidgetId: this.changedKeys ?? {},
            deleteComponentFn: this.deleteComponentFn,
          });
        }
        break;
    }
  }

  private handleColumnActions({
    action,
    itemId,
    itemExists,
    canvasWidget,
    widgetId,
    itemCount,
  }: ItemHandlerProps<ColumnAction>) {
    const changes = this.nestedChangesByWidgetId[widgetId];
    if (changes == null) {
      throw new Error(
        `Changes for widget ${widgetId} not found in ClarkProcessor`,
      );
    }

    // Always track the widgetId in the changedKeys when modifying table columns
    if (!this.changedKeys[widgetId]) {
      this.changedKeys[widgetId] = [];
    }

    const tableCanvasWidget = canvasWidget as TableWidgetProps;

    switch (action.action) {
      case "set":
        if (!itemExists) {
          addColumns({
            widget: tableCanvasWidget,
            changes,
            numColumns: itemCount,
            columnName: String(itemId),
            changedKeys: this.changedKeys[widgetId],
          });
        }
        if (action.property) {
          updateColumns({
            action: action as SetColumnAction,
            widget: tableCanvasWidget,
            changes,
            changedKeys: this.changedKeys[widgetId],
            dataTree: this.context.dataTree,
            routes: this.context.routes,
            columnName: String(itemId),
          });
        }
        break;
      case "add":
        if (itemExists) {
          updateColumns({
            action: action as AddColumnEventAction,
            widget: tableCanvasWidget,
            changes,
            dataTree: this.context.dataTree,
            routes: this.context.routes,
            columnName: String(itemId),
            changedKeys: this.changedKeys[widgetId],
          });
        }
        break;
      case "remove":
        if (itemExists && "property" in action) {
          updateColumns({
            action: action as RemoveColumnEventAction,
            widget: tableCanvasWidget,
            changes,
            dataTree: this.context.dataTree,
            routes: this.context.routes,
            columnName: String(itemId),
            changedKeys: this.changedKeys[widgetId],
          });

          // Record property removal
          this.changedKeys[widgetId].push(
            `primaryColumns.${String(itemId)}.${action.property}`,
          );
          if (tableCanvasWidget.derivedColumns?.[String(itemId)]) {
            this.changedKeys[widgetId].push(
              `derivedColumns.${String(itemId)}.${action.property}`,
            );
          }
        } else if (itemExists) {
          removeColumns({
            widget: tableCanvasWidget,
            changes,
            columnName: String(itemId),
            changedKeys: this.changedKeys[widgetId],
            deleteComponentPropertiesFn: this.deleteComponentPropertiesFn,
          });

          // Record column removal
          this.changedKeys[widgetId].push(`primaryColumns.${String(itemId)}`);
          if (tableCanvasWidget.derivedColumns?.[String(itemId)]) {
            this.changedKeys[widgetId].push(`derivedColumns.${String(itemId)}`);
          }
        }
        break;
    }
  }

  private handleMenuItemActions({
    action,
    itemId,
    itemExists,
    widgetId,
    canvasWidget,
  }: ItemHandlerProps<MenuItemAction>) {
    const metadata = this.metadataByWidgetId[widgetId];
    if (metadata == null) {
      throw new Error(
        `Metadata for widget ${widgetId} not found in ClarkProcessor`,
      );
    }
    if (typeof itemId === "string") {
      itemId = parseInt(itemId, 10);
    }
    if (!this.changedKeys[widgetId]) {
      this.changedKeys[widgetId] = [];
    }
    const itemIndex =
      typeof itemId === "string" ? parseInt(itemId, 10) : itemId;
    const idsToIndices = metadata.idsToIndices;
    const indicesToIds = Object.fromEntries(
      Object.entries(idsToIndices).map(([id, index]) => [index, id]),
    );
    const menuItemId = indicesToIds[itemIndex];
    switch (action.action) {
      case "set":
        if (!itemExists) {
          const newMenuItemId = addMenuItem({
            property: action.property as string,
            value: action.value,
            widget: canvasWidget,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeys: this.changedKeys ?? {},
          });
          set(
            this.metadataByWidgetId,
            `${widgetId}.idsToIndices.${newMenuItemId}`,
            itemId,
          );
        } else {
          updateMenuItem({
            menuItemId,
            property: action.property as string,
            value: action.value,
            widget: canvasWidget,
            changedKeys: this.changedKeys ?? {},
            changesByWidgetId: this.nestedChangesByWidgetId,
          });
        }
        break;
      case "remove": {
        if (itemExists && "property" in action) {
          updateMenuItem({
            menuItemId,
            property: action.property as string,
            value: undefined,
            widget: canvasWidget,
            changedKeys: this.changedKeys ?? {},
            changesByWidgetId: this.nestedChangesByWidgetId,
          });
        } else if (itemExists) {
          removeMenuItem({
            widget: canvasWidget,
            menuItemId,
            changesByWidgetId: this.nestedChangesByWidgetId,
            changedKeys: this.changedKeys ?? {},
          });
        }
        break;
      }
      case "reset": {
        if (itemExists && action.property) {
          updateMenuItem({
            menuItemId,
            property: action.property as string,
            value: undefined,
            widget: canvasWidget,
            changedKeys: this.changedKeys ?? {},
            changesByWidgetId: this.nestedChangesByWidgetId,
          });
        }
      }
    }
  }

  private handleKvActions({
    action,
    itemId,
    itemExists,
    canvasWidget,
    widgetId,
  }: ItemHandlerProps<KvAction>) {
    const changes = this.nestedChangesByWidgetId[widgetId];
    if (changes == null) {
      throw new Error(
        `Changes for widget ${widgetId} not found in ClarkProcessor`,
      );
    }
    if (!this.changedKeys[widgetId]) {
      this.changedKeys[widgetId] = [];
    }
    if (!itemExists) {
      addKvItem({
        action: action,
        widget: canvasWidget,
        changes,
        dataTree: this.context.dataTree,
        routes: this.context.routes,
        kvProperty: String(itemId),
        updateComponentFn: this.updateComponentFn,
        changedKeys: this.changedKeys ?? {},
      });
    } else if (itemExists && action.action === "remove" && !action.property) {
      removeKvItem({
        widget: canvasWidget,
        changes,
        kvProperty: String(itemId),
        deleteComponentPropertiesFn: this.deleteComponentPropertiesFn,
        changedKeys: this.changedKeys ?? {},
      });
    } else {
      updateKvItem({
        action: action,
        widget: canvasWidget,
        changes,
        dataTree: this.context.dataTree,
        routes: this.context.routes,
        kvProperty: String(itemId),
        updateComponentFn: this.updateComponentFn,
        changedKeys: this.changedKeys ?? {},
      });
    }
  }

  private createNewWidgetInDataTree(widgetId: string) {
    const type: WidgetTypes | undefined = this.nestedChangesByWidgetId[widgetId]
      ?.type as WidgetTypes | undefined;
    const parentId = this.nestedChangesByWidgetId[widgetId]?.parentId;

    if (!type) {
      throw new Error(
        `Widget with id ${widgetId} has no type and was not found in canvasWidgets`,
      );
    }

    if (!parentId) {
      throw new Error(
        `Widget with id ${widgetId} has no parentId and was not found in canvasWidgets`,
      );
    }

    const newWidget = fastClone(this.nestedChangesByWidgetId[widgetId]);
    this.dataTreeChanges[widgetId] = newWidget;
  }

  private getInitialWidgetProps(widgetType: WidgetTypes, parentId: string) {
    const baseProps = {
      ...WidgetConfigResponse.getCreateConfig(
        widgetType,
        this.context.featureFlags,
        this.context.theme,
      ),
      left: Dimension.gridUnit(0),
      top: Dimension.gridUnit(0),
    };

    // get the existing parent widget
    const parentWidget = this.context.canvasWidgets[parentId];
    const parentLayout = parentWidget?.layout;

    if (!parentLayout) {
      return baseProps;
    }

    // Naive positioning for fixed grid
    // todo: eric - ai team - improve this
    if (parentLayout === CanvasLayout.FIXED) {
      // find parent widget child with highest top + height value
      const children = parentWidget.children || [];
      let maxBottom = 0;

      children.forEach((childId) => {
        const child = this.context.canvasWidgets[childId];
        if (child) {
          const childTop = (child.top as Dimension).value;
          const childHeight = (child.height as Dimension).value;
          const bottom = childTop + childHeight;
          maxBottom = Math.max(maxBottom, bottom);
        }
      });

      // now find parent widget child with highest top + height value
      // from any new possible children that were created by Clark
      const newChildren = (this.nestedChangesByWidgetId[parentId]?.children ||
        []) as string[];
      newChildren.forEach((childId) => {
        const child =
          this.context.canvasWidgets[childId] ??
          this.nestedChangesByWidgetId[childId];
        if (child) {
          const childTop = (child.top as Dimension).value;
          const childHeight = (child.height as Dimension).value;
          const bottom = childTop + childHeight;
          maxBottom = Math.max(maxBottom, bottom);
        }
      });

      baseProps.top = Dimension.gridUnit(maxBottom === 0 ? 1 : maxBottom + 1);
      baseProps.left = Dimension.gridUnit(0);
    }

    return baseProps;
  }

  private createDefaultWidgetAndChildWidgets(
    widgetId: string,
    type: WidgetTypes,
    widgetName: string,
    parentId: string,
  ) {
    const parent = this.context.canvasWidgets[parentId] as FlattenedWidgetProps;

    if (!parent) {
      throw new Error(
        `Parent widget with id ${parentId} not found in canvasWidgets`,
      );
    }

    const initialNewWidgetProps = fastClone(
      this.getInitialWidgetProps(type, parentId),
    );

    this.nestedChangesByWidgetId[widgetId] = {
      ...initialNewWidgetProps,
      widgetName,
      widgetId,
      parentId: parent.widgetId,
      type,
    };

    this.setChangedKeysForNewWidget(widgetId);
  }
}
